var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"headline primary--text"},[_vm._v(_vm._s(_vm.titulo))]),_c('v-spacer'),(_vm.selected.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"slot":"activator","outlined":"","ripple":"","color":"primary","icon":"icon"},on:{"click":function($event){_vm.dialog = true}},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,3033231469)},[_c('span',[_vm._v("Excluir Selecionado(s)")])]):_vm._e(),(_vm.selected.length == 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"outlined":"","ripple":"","color":"primary","icon":"icon"},on:{"click":_vm.editar}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,2413343208)},[_c('span',[_vm._v("Editar Selecionado")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"outlined":"","ripple":"","color":"primary","icon":"icon"},on:{"click":_vm.novo}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Adicionar Novo")])])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('v-select',{staticClass:"mr-1",attrs:{"hide-details":"","filled":"","rounded":"","dense":"","items":_vm.camposFiltro},model:{value:(_vm.campoFiltro),callback:function ($$v) {_vm.campoFiltro=$$v},expression:"campoFiltro"}})],1),_c('v-col',{attrs:{"md":"8","cols":"12"}},[_c('v-text-field',{staticClass:"mr-1",attrs:{"label":"Pesquisar","single-line":"","hide-details":"","clearable":"","filled":"","rounded":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.pesquisar.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"md":"2","cols":"4"}},[_c('v-btn',{attrs:{"ripple":"","color":"primary","rounded":""},on:{"click":_vm.pesquisar}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" buscar ")],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"selected-key":"id","show-select":"","footer-props":{ itemsPerPageOptions: [5, 10, 25, 50, 100] },"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.conteudo",fn:function(ref){
var item = ref.item;
return [_c('v-banner',{staticStyle:{"overflow":"auto"},attrs:{"max-height":"400"}},[_c('span',{domProps:{"innerHTML":_vm._s(item.conteudo)}})])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.confirm.title))]),_c('v-card-text',[_vm._v(_vm._s(_vm.confirm.contentHtml))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"red--text darken-1",attrs:{"text":""},nativeOn:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.confirm.cancel))]),_c('v-btn',{staticClass:"green--text darken-1",attrs:{"text":""},nativeOn:{"click":function($event){return _vm.excluir()}}},[_vm._v(_vm._s(_vm.confirm.ok))])],1)],1)],1),_c('alerta')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }