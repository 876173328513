<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>
        <span class="headline primary--text">{{ titulo }}</span>
        <v-spacer></v-spacer>
        <v-tooltip top v-if="selected.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              class="mr-2"
              ripple
              color="primary"
              icon="icon"
              slot="activator"
              @click="dialog = true"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Excluir Selecionado(s)</span>
        </v-tooltip>
        <v-tooltip top v-if="selected.length == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              class="mr-2"
              ripple
              color="primary"
              icon="icon"
              @click="editar"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar Selecionado</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              class="mr-2"
              ripple
              color="primary"
              icon="icon"
              @click="novo"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar Novo</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col md="2" cols="12">
              <v-select
                v-model="campoFiltro"
                class="mr-1"
                hide-details
                filled
                rounded
                dense
                :items="camposFiltro"
              ></v-select>
            </v-col>

            <v-col md="8" cols="12">
              <v-text-field
                label="Pesquisar"
                single-line
                hide-details
                clearable
                filled
                rounded
                dense
                class="mr-1"
                v-model="search"
                v-on:keyup.enter="pesquisar"
              ></v-text-field>
            </v-col>
            <v-col md="2" cols="4">
              <v-btn ripple color="primary" rounded @click="pesquisar">
                <v-icon>mdi-magnify</v-icon> buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-data-table
        v-bind:headers="headers"
        v-bind:items="items"
        v-model="selected"
        selected-key="id"
        show-select
        :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100] }"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
      >
        <template v-slot:[`item.conteudo`]="{ item }">
          <v-banner max-height="400" style="overflow: auto;">
            <span v-html="item.conteudo"></span>
          </v-banner>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" persistent max-width="450">
        <v-card>
          <v-card-title>{{ confirm.title }}</v-card-title>
          <v-card-text>{{ confirm.contentHtml }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="red--text darken-1"
              @click.native="dialog = false"
              >{{ confirm.cancel }}</v-btn
            >
            <v-btn
              text
              class="green--text darken-1"
              @click.native="excluir()"
              >{{ confirm.ok }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <alerta></alerta>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "auto-texto-list",
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["atalho"],
        sortDesc: [false],
      },
      camposFiltro: [
        { value: "atalho", text: "Atalho" },
        { value: "conteudo", text: "Conteúdo" },
      ],
      campoFiltro: "atalho",
      loading: true,
      items: [],
      total: null,
      titulo: "Auto Textos",
      confirm: {
        title: "Atenção",
        contentHtml:
          "Tem certeza que deseja apagar auto texto(s) selecionada(s).",
        ok: "Sim",
        cancel: "Não",
      },
      dialog: false,
      search: "",
      selected: [],
      headers: [
        {
          text: "Atalho",
          align: "left",
          sortable: true,
          value: "atalho",
        },
        {
          text: "Conteúdo",
          align: "left",
          sortable: true,
          value: "conteudo",
        },
      ],
    };
  },
  components: {
    Alerta,
  },
  computed: {
    ...mapGetters(["autoTextoQuery"]),
  },
  watch: {
    search() {
      this.setAutotextoQuery({ query: this.search });
    },
    options: {
      handler() {
        this.atualizarLista();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["setUltimaTela", "setAlerta", "setAutotextoQuery"]),
    excluir() {
      var self = this;
      Object.keys(this.selected).forEach((index) => {
        const selecionado = this.selected[index];

        this.$http
          .delete(`/autoTexto/${selecionado.id}`)
          .then(() => {
            self.atualizarLista();
            self.setAlerta({
              tipoAlerta: "success",
              textAlerta: "Auto texto excluído com sucesso",
            });
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao excluir auto texto",
            });
          });

        setTimeout(function () {
          self.selected = [];
        }, 3000);

        this.dialog = false;
      });
    },
    novo() {
      this.$router.push({ name: "AutoTextoCreate" });
    },
    editar() {
      var index = Object.keys(this.selected)[0];
      const selecao = this.selected[index];
      this.$router.push({ name: "AutoTextoEdit", params: { id: selecao.id } });
    },
    pesquisar() {
      this.options.page = 1;
      this.atualizarLista();
    },
    atualizarLista() {
      var self = this;
      this.loading = true;
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      var offset = page - 1;
      let order = sortDesc[0] ? "desc" : "asc";
      let sort = sortBy.length > 0 ? sortBy[0] : "atalho";

      this.$http
        .get(
          `/autoTexto?sort=${sort}&order=${order}&offset=${offset}&max=${itemsPerPage}&${
            this.campoFiltro
          }=${this.search ? this.search : ""}`
        )
        .then((response) => {
          this.items = response.data.content;
          this.total = response.data.totalSize;
          this.options.itemsPerPage = response.data.size;
          this.loading = false;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar auto texto",
          });
        });
    },
  },
  created() {
    this.setUltimaTela({ tela: this.$route.fullPath });
    this.search = this.autoTextoQuery;
  },
};
</script>
